.errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    border-radius: 0.3rem;
    outline: 0;
}

.pdf-style {
  width :1000px;
  height:500px;
}

.det-width {
   width:50% !important;
}
.btn-outline-secondary1 {
  border-color: #d8d8d8;
}

.optMsg {
  color: #cc0000;
 
}
.table th, .table td {
  /* vertical-align: middle; */
  font-size: 0.875rem;
  line-height: 1;
  /* white-space: nowrap; */
}

.tdcss {
  font-size: 0.875rem;
  line-height: 1;
  
}

